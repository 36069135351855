<template>
  <div class="fleetDashboard" :class="'fleetDashboard--' + dashboardType">
    <div v-if="dashboardType === 'provider'" class="bookingDashboard bookingDashboard--list">
      <div class="bookingDashboard__header bookingDashboard__header--provider">
        <div class="bookingDashboard__title">Availability List</div>
        <router-link class="buttonCreate" to="charter-uploads/new">Create</router-link>
      </div>
      <div class="bookingDashboard__table">
        <div class="bookingDashboard__table-header">
          <div class="bookingDashboard__table-cell">Tail number</div>
          <div class="bookingDashboard__table-cell">Model</div>
          <div class="bookingDashboard__table-cell">Type</div>
          <div class="bookingDashboard__table-cell"></div>
        </div>
        <div class="bookingDashboard__table-body">
          <vue-scroll :ops="ops">
            <div
              class="bookingDashboard__table-row"
              v-for="item of availabilities"
              :key="item.availabilityId"
            >
              <div class="bookingDashboard__table-cell aircraftNumber">{{ item.aircraftNumber }}</div>
              <div class="bookingDashboard__table-cell aircraftModel">{{ item.aircraftModel }}</div>
              <div class="bookingDashboard__table-cell aircraftType">
                {{ item.aircraftType }}
                <router-link class="bookingDashboard__table-detail" :to="'charter-uploads/'+ item.availabilityId">click
                  for details
                </router-link>
              </div>
              <div class="bookingDashboard__table-cell" style="height: 21.11px;"><div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.availabilityId)">
                <SvgIcon name="close"/>
              </div></div>
              </div>
          </vue-scroll>
        </div>
      </div>
    </div>
    <!--        <div class="fleetDashboard__inner">-->
    <!--          <router-link class="fleetDashboard__button" to="/provider-dashboard/new-aircraft">-->
    <!--            <span class="text">Add new aircraft</span>-->
    <!--          </router-link>-->
    <!--          <div class="fleetDashboard__subtitle">Fleet</div>-->
    <!--          <div v-if="!fleet.length" class="fleetDashboard__empty js-aircraft-empty">no aircraft</div>-->
    <!--          <div class="fleetDashboard__list">-->
    <!--            <div-->
    <!--              v-for="item of fleet"-->
    <!--              :key="item.id"-->
    <!--              class="fleetDashboard__list-item js-aircraft-item"-->
    <!--            >-->
    <!--              <router-link-->
    <!--                :to="'/provider-dashboard/fleet/' + item.id"-->
    <!--                class="fleetDashboard__list-item-title"-->
    <!--              >-->
    <!--                <b>Aircraft {{item.counter}}: </b>{{item.title}}-->
    <!--              </router-link>-->
    <!--              <div class="fleetDashboard__list-item-remove js-remove-aircraft" @click="showModal(item.id)">-->
    <!--                <SvgIcon name="close"/>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <QuestionPopup
      :name="'questionPopupFleet'"
      :title="'The aircraft availability will be removed'"
      :text="'Are you sure?'"
      @remove="remove"
    />
  </div>

</template>

<script>
import {Tabs} from '@/js/plugins/tabs'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import QuestionPopup from '@/components/common/modals/QuestionPopup';
import '../../common/Fleet/Fleet.styl'
import './CharterAvailabilitiesList.styl'
import {aircraftsAPI} from '@/api/aircrafts';

export default {
  props: ['dashboardType'],
  name: 'Fleet',
  data: () => ({
    tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
    ops: {
      rail: {
        size: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
  }),
  computed: {
    availabilities () {
      return this.$store.state.availabilities.charterAvailabilities
    },
  },
  async created () {
    await this.$store.dispatch('getCharterAvailabilities')
  },
  mounted () {
    this.tabs.addActiveForMouted()
  },
  methods: {
    showModal (id) {
      this.$modal.show('questionPopupFleet')
      this.$store.commit('insertCurrentFleet', id)
    },
    async remove () {
      this.loading = true

      try {
        await aircraftsAPI.removeAircraftAvailability({ availabilityId: this.$store.state.fleet.currentFleet })

        window.location.reload();
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    tabActivate (event) {
      this.tabs.addActiveForClick(event.currentTarget)
    },
  },
  components: {
    SvgIcon,
    QuestionPopup,
  },
}
</script>
